import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { menusConfig } from '@/config/menu.config'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const isLocationMatch = (
  targetLocation: any,
  locationName: any,
): boolean => {
  return (
    locationName === targetLocation ||
    locationName.startsWith(`${targetLocation}/`)
  )
}

export const RGBToHex = (r: number, g: number, b: number): string => {
  const componentToHex = (c: number): string => {
    const hex = c.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  const redHex: string = componentToHex(r)
  const greenHex: string = componentToHex(g)
  const blueHex: string = componentToHex(b)

  return '#' + redHex + greenHex + blueHex
}

export function hslToHex(hsl: string): string {
  // Remove "hsla(" and ")" from the HSL string
  const hslValues = hsl.replace('hsla(', '').replace(')', '')

  // Split the HSL string into an array of H, S, and L values
  const [h, s, l] = hslValues.split(' ').map((value) => {
    if (value.endsWith('%')) {
      // Remove the "%" sign and parse as a float
      return parseFloat(value.slice(0, -1))
    } else {
      // Parse as an integer
      return parseInt(value)
    }
  })

  // Function to convert HSL to RGB
  function hslToRgb(h: number, s: number, l: number): string {
    h /= 360
    s /= 100
    l /= 100

    let r, g, b

    if (s === 0) {
      r = g = b = l
    } else {
      const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) t += 1
        if (t > 1) t -= 1
        if (t < 1 / 6) return p + (q - p) * 6 * t
        if (t < 1 / 2) return q
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
        return p
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = hue2rgb(p, q, h + 1 / 3)
      g = hue2rgb(p, q, h)
      b = hue2rgb(p, q, h - 1 / 3)
    }

    // Convert RGB values to integers
    const rInt = Math.round(r * 255)
    const gInt = Math.round(g * 255)
    const bInt = Math.round(b * 255)

    // Convert RGB values to a hex color code
    const rgbToHex = (value: number): string => {
      const hex = value.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }

    return `#${rgbToHex(rInt)}${rgbToHex(gInt)}${rgbToHex(bInt)}`
  }

  // Call the hslToRgb function and return the hex color code
  return hslToRgb(h, s, l)
}

export const hexToRGB = (hex: string, alpha?: number): string => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export const formatTime = (time: number | Date | string): string => {
  if (!time) return ''

  const date = new Date(time)
  const formattedTime = date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  return formattedTime
}

// object check
export function isObjectNotEmpty(obj: any): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return false
  }
  return Object.keys(obj).length > 0
}

export const formatDate = (date: string | number | Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return new Date(date).toLocaleDateString('en-US', options)
}

// random word
export function getWords(inputString: string): string {
  // Remove spaces from the input string
  const stringWithoutSpaces = inputString.replace(/\s/g, '')

  // Extract the first three characters
  return stringWithoutSpaces.substring(0, 3)
}

// for path name
export function getDynamicPath(pathname: any): any {
  const prefixes = ['en', 'bn', 'ar']

  for (const prefix of prefixes) {
    if (pathname.startsWith(`/${prefix}/`)) {
      return `/${pathname.slice(prefix.length + 2)}`
    }
  }

  return pathname
}

export function formatDateToTimezone(
  dateStr: string,
  timezone = 'Australia/Sydney',
) {
  const date = new Date(dateStr)
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  }
  const formattedDate = new Intl.DateTimeFormat('en-AU', options).format(date)
  return formattedDate
}

export const findChangedProperties = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
  returnAll = false,
) => {
  return Object.keys(obj1).reduce((changed: { [key: string]: any }, key) => {
    if (obj1[key] !== obj2[key]) {
      if (!returnAll) {
        changed[key] = obj2[key]
      } else {
        changed[key] = { oldValue: obj1[key], newValue: obj2[key] }
      }
    }
    return changed
  }, {})
}

export const generateBreadcrumbs = (
  payload: string[],
): { name: string; href?: string }[] => {
  let previousHref: string | undefined
  return payload.map((item: string, index: number) => {
    const currentMenu = menusConfig.find((menuItem: any) =>
      menuItem.href.includes(item.toLowerCase()),
    )
    const breadcrumb: { name: string; href?: string | undefined } = currentMenu
      ? { name: currentMenu.title, href: currentMenu.href }
      : { name: item, href: `${previousHref}/${item}` }

    previousHref = breadcrumb.href
    index === payload.length - 1 && delete breadcrumb.href

    return breadcrumb
  })
}

export const getUrlHost = (url: string): string => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return ''
  }
}

export const getExtensionFromUrl = (url: string) => {
  const path = new URL(url).pathname
  const lastSegment = path.split('/').pop() // Get the last part after the final slash
  const extension = lastSegment?.includes('.')
    ? lastSegment?.split('.').pop()
    : ''
  return extension || ''
}

export const getAllowedExtensions = () => {
  return {
    ppt: ['.ppt', '.pptx'],
    pdf: ['.pdf'],
    image: ['.gif', '.jpg', '.jpeg', '.png', '.svg', '.webp'],
    doc: ['.doc', '.docx'],
  }
}

export const getFloat = (value: string | null): number =>
  !value || isNaN(Number(value)) ? 0 : parseFloat(value)

// e.g. #triggerAction=updateBudget
//  { triggerAction : updateBudget }
export const parseLocationHash = (
  value: string,
): Record<string, string | undefined> => {
  const [key, val] = value.replace('#', '').split('=')
  return { [key]: val }
}

// check if the url is valid
export const isUrl = (url: string): boolean => {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?{}#[\]@!\$%&'\(\)\*\+,;=.]+$/gm.test(
    url,
  )
}

/**
 * Capitalize the first letter of a string
 * @param {string} str The string to capitalize
 * @returns {string} The capitalized string
 */
export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

/**
 * Add protocol to a URL if it doesn't have one
 * @param url The URL to add the protocol to
 * @returns The URL with the protocol added
 */
export const addProtocol = (url: string, secure = false): string => {
  return url.startsWith('http') ? url : `http${secure ? 's' : ''}://${url}`
}

/**
 * Recursively removes null, undefined, empty strings, and empty arrays from an object
 * @param {T} obj - The object to strip nullish values from
 * @returns {T} A new object with all nullish values removed recursively
 * @template T - Type extending Record<string, unknown>
 * @example
 * const input = {
 *   a: 1,
 *   b: null,
 *   c: { d: '', e: { f: null }, g: [1, '', null] },
 *   h: []
 * };
 * const result = stripNulls(input);
 * // result = { a: 1, c: { g: [1] } }
 */
export const stripNulls = <T extends Record<string, unknown>>(obj: T): T => {
  return Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => {
        if (v === null || v === undefined || v === '') return false
        if (Array.isArray(v)) return v.length > 0
        return true
      })
      .map(([k, v]) => {
        if (Array.isArray(v)) {
          const cleanArray = v
            .filter(
              (item) => item !== null && item !== undefined && item !== '',
            )
            .map((item) => {
              if (typeof item === 'object' && item !== null) {
                return stripNulls(item as Record<string, unknown>)
              }
              return item
            })
          return [k, cleanArray.length > 0 ? cleanArray : undefined]
        }
        if (typeof v === 'object' && v !== null) {
          const cleaned = stripNulls(v as Record<string, unknown>)
          return [k, Object.keys(cleaned).length > 0 ? cleaned : undefined]
        }
        return [k, v]
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, v]) => v !== undefined),
  ) as T
}
